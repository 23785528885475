let activeTooltipBtn = null;

function addMultipleEventListener(element, events, handler) {
    events.forEach(e => element.addEventListener(e, handler));
}

addMultipleEventListener(document, ["click", "keypress"], event => {
    if (event.type === "click" || event.key === "Enter") {
        const tooltipBtn = event.target.closest("[data-svg-map]");
        const existingTooltip = document.querySelector(".svg-map-tooltip");
        const isSameButton = tooltipBtn === activeTooltipBtn;

        if (isSameButton) {
            closeTooltip(existingTooltip);
            activeTooltipBtn = null;
        } else if (tooltipBtn) {
            closeTooltip(existingTooltip);
            createTooltip(tooltipBtn);
            activeTooltipBtn = tooltipBtn;
        } else {
            closeTooltip(existingTooltip);
            activeTooltipBtn = null;
        }
    }
});

function createTooltip(tooltipBtn) {
    const title = tooltipBtn.getAttribute("data-svg-map-title");
    const address = tooltipBtn.getAttribute("data-svg-map-address");
    const link = tooltipBtn.getAttribute("data-svg-map-link");

    const tooltip = document.createElement("div");
    tooltip.classList.add("svg-map-tooltip");
    tooltip.insertAdjacentHTML(
        "afterbegin",
        `<div class="text-sm text-bold text-capture mb-2">${title}</div>
    <div>${address}</div>
    <a class="btn btn-md btn-success mt-16" href="${link}" target="_blank">Перейти</a>
    <div class="svg-map-tooltip__arrow-border"></div>
    <div class="svg-map-tooltip__arrow"></div>`
    );

    document.body.insertAdjacentElement("beforeend", tooltip);
    tooltipPosition(tooltipBtn, tooltip);

    const tooltipLink = tooltip.querySelector("a");
    tooltipLink.focus();

    tooltipLink.addEventListener("keydown", event => {
        if (event.key === "Tab") {
            closeTooltip(tooltip);
            activeTooltipBtn.focus();
        }
    });
}

function tooltipPosition(tooltipBtn, tooltip) {
    const btnX = tooltipBtn.getBoundingClientRect().x;
    const btnWidth = tooltipBtn.getBoundingClientRect().width;
    const btnY = tooltipBtn.getBoundingClientRect().y + pageYOffset;
    const tooltipWidth = tooltip.getBoundingClientRect().width;
    const screenPadding = 10;
    const arrowBorder = tooltip.querySelector(".svg-map-tooltip__arrow-border");
    const arrow = tooltip.querySelector(".svg-map-tooltip__arrow");

    tooltip.style.left = btnX + btnWidth / 2 + "px";
    tooltip.style.top = btnY + "px";
    arrow.style.left = "50%";
    arrowBorder.style.left = "50%";

    if (btnX < tooltipWidth / 2 + screenPadding) {
        tooltip.style.left = tooltipWidth / 2 + screenPadding + "px";
        arrow.style.left = btnX + btnWidth / 2 - screenPadding + "px";
        arrowBorder.style.left = btnX + btnWidth / 2 - screenPadding + "px";
    } else if (
        btnX + btnWidth / 2 + tooltipWidth / 2 + screenPadding >
        window.outerWidth
    ) {
        tooltip.style.left =
            window.outerWidth - tooltipWidth / 2 - screenPadding + "px";
        arrow.style.left =
            btnX -
            window.outerWidth +
            tooltipWidth +
            btnWidth / 2 +
            screenPadding +
            "px";
        arrowBorder.style.left =
            btnX -
            window.outerWidth +
            tooltipWidth +
            btnWidth / 2 +
            screenPadding +
            "px";
    }
}

function closeTooltip(existingTooltip) {
    if (existingTooltip) {
        existingTooltip.remove();
    }
}
