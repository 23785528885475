const tab = function () {
    const tabNav = document.querySelectorAll('[data-tab-name]'),
    tabContent = document.querySelectorAll('[data-tab-content]')
    
    tabContent.forEach(item => {
        item.style.display = 'none'
    })
    tabContent[0].style.display = 'block'

    tabNav.forEach(item => {
        item.addEventListener('click', selectTabNav)
    })

    function selectTabNav() {
        const tabName = this.getAttribute('data-tab-name')
        selectTabContent(tabName)
    }

    function selectTabContent(tabName) {
        tabContent.forEach(item => {
            if (item.getAttribute('data-tab-content') == tabName) {
                item.style.display = 'block'
            } else {
                item.style.display = 'none'
            }
        })
    }
}

tab()