const body = document.querySelector("body");
const stepper = document.querySelector("[data-stepper]");
const categories = window.assistant;

if (body.contains(stepper)) {
  const btnPrevious = document.querySelector("[data-btn-previous]");
  const btnNext = document.querySelector("[data-btn-next]");
  const stepperError = document.querySelectorAll("[data-stepper-error]");
  let currentTab = 0;

  showTab(currentTab);

  function showTab(n) {
    const formTabs = document.querySelectorAll("[data-step]");
    const stepperDot = document.querySelectorAll("[data-stepper-dot]");
    formTabs[n].classList.remove("d-none");
    stepperDot[n].classList.add("active");
    if (stepperDot[n - 1]) {
      stepperDot[n - 1].classList.add("done");
    }

    if (n === 0) {
      btnPrevious.style.display = "none";
    } else {
      btnPrevious.style.display = "flex";
    }

    if (n === formTabs.length - 1) {
      btnNext.style.display = "none";
    } else {
      btnNext.style.display = "flex";
    }
  }

  function nextPrev(n) {
    const formTabs = document.querySelectorAll("[data-step]");
    const stepperDot = document.querySelectorAll("[data-stepper-dot]");
    if (n === 1 && !validateForm()) return false;
    formTabs[currentTab].classList.add("d-none");
    stepperDot[currentTab].classList.remove("active");
    if (stepperError[currentTab]) {
      stepperError[currentTab].classList.remove("error");
    }
    currentTab = currentTab + n;
    showTab(currentTab);
  }

  function validateForm() {
    let valid = true;
    const x = document.querySelectorAll("[data-step]");
    const inputType = x[currentTab].getElementsByTagName("input");

    for (let i = 0; i < inputType.length; i++) {
      if (inputType[i].value === "") {
        stepperError[currentTab].classList.add("error");
        valid = false;
      }
    }
    return valid;
  }

  btnPrevious.addEventListener("click", ()=> {
    nextPrev(-1);
  });

  btnNext.addEventListener("click", ()=> {
    nextPrev(1);
    formationResult();
  });


  const stepperResult = document.querySelector("[data-stepper-result]");
  const stepperPageLink = document.querySelector("[data-stepper-page-link]");

  function formationResult() {
    const firstInputValue = firstSelectInput.value;
    const secondInputValue = secondSelectInput.value;

    if (firstInputValue !== "" && secondInputValue !== "") {
      stepperResult.innerHTML = "";

      const category = Object.values(categories).find((cat) => cat.title === firstInputValue);

      if (category) {
        const node = category.nodes.find((node) => node.translates === secondInputValue);
        stepperPageLink.setAttribute("href", `${node.url}`);

        if (node) {
          const news = node.news;

          news.forEach((item) => {
            const {image, image_alt, title, date_published_at, href} = item;

            const newsItem = `
              <div class="d-flex flex-sm-column h-100 bg-white p-8 p-sm-12 rounded-12 hover-shadow">
                  <div class="min-w-88-px w-30 w-sm-auto">
                      <div class="block-img block-img_67 mb-16">
                          <img src="${image}" alt="${image_alt}">
                      </div>
                  </div>
                  <div class="d-flex flex-column flex-grow-1 w-70 w-sm-auto pl-12 pl-sm-0">
                      <div class="title-h3 mb-8 text-line-clamp-3">${title}</div>
                      <div class="d-flex align-items-center mb-16">
                          <svg class="mr-4" width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.33398 7.8525C1.33398 4.17342 4.31398 1.1875 7.99398 1.1875C11.6807 1.1875 14.6673 4.17342 14.6673 7.8525C14.6673 11.5316 11.6807 14.5175 7.99398 14.5175C4.31398 14.5175 1.33398 11.5316 1.33398 7.8525ZM2.66797 7.85264C2.66797 10.7986 5.05464 13.1846 8.0013 13.1846C10.948 13.1846 13.3346 10.7986 13.3346 7.85264C13.3346 4.90671 10.948 2.52064 8.0013 2.52064C5.05464 2.52064 2.66797 4.90671 2.66797 7.85264Z" fill="#C0C0C0"/>
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.66732 4.34619H7.33398V9.01519H7.33414L10.7982 11.0162L11.4648 9.86088L8.66732 8.24491V4.34619Z" fill="#C0C0C0"/>
                          </svg>
                          <div class="text-gray2">${date_published_at}</div>
                      </div>
                      <a href="${href}" class="btn btn-md btn-success mt-auto">Детальніше</a>
                  </div>
              </div>`;

            stepperResult.insertAdjacentHTML("beforeend", newsItem);
          });
        }
      }
    }
  }
}

const firstSelectButtons = document.querySelectorAll("[data-first-select-buttons]");
const firstSelectInput = document.querySelector("[data-first-select-input]");
const secondSelectButtons = document.querySelectorAll("[data-second-select-buttons]");
const secondSelectTitle = document.querySelector("[data-second-select-title]");
const secondSelectInput = document.querySelector("[data-second-select-input]");
let isMatch = "";

firstSelectButtons.forEach((button)=> {
  button.addEventListener("click", ()=> {
    const firstSelectValue = button.getAttribute("data-first-select-buttons");
    secondSelectButtons.forEach((secondButton)=> {
      const secondSelectValue = secondButton.getAttribute("data-second-select-buttons");

      if (secondSelectValue !== firstSelectValue) {
        secondButton.style.display = "none";
      } else {
        secondButton.style = "";
      }
    });

    if (firstSelectValue !== isMatch) {
      secondSelectTitle.textContent = "Оберіть варіант з переліку";
      secondSelectTitle.style = "";
      secondSelectInput.value = "";
    }

    isMatch = firstSelectValue;
  });
});

