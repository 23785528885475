const departCards = document.querySelectorAll('.depart-card')

for (const departCard of departCards) {
    departCard.addEventListener('touchstart', function() {
        departCard.classList.add('depart-card_touch')
    },{passive: true})

    departCard.addEventListener('touchend', function() {
        departCard.classList.remove('depart-card_touch')
    },{passive: true})
}