import {Carousel, Fancybox} from "@fancyapps/ui";
import {Autoplay} from "@fancyapps/ui/dist/carousel.autoplay.esm.js";
Carousel.Plugins.Autoplay = Autoplay;

const sliders = document.querySelectorAll("[data-slider]");

// export function initializeSlider() {
window.initializeSlider = function(sliders) {
  for (const slider of sliders) {
    const disableDots = slider.hasAttribute("data-slider-no-dots");
    const isInfinite = slider.hasAttribute("data-slider-no-infinite");
    const enableAutoplay = slider.hasAttribute("data-slider-autoplay");

    const options = {
      Dots: !disableDots,
      center: false,
      slidesPerPage: 1,
      friction: 0.85,
      infinite: !isInfinite,
      on: {
        selectSlide: () => {
          setTimeout(() => {
            addClasses(slider);
          }, 1);
        },
      },
    };

    if (enableAutoplay) {
      options.Autoplay = {
        timeout: 5000,
      };
    } else {
      options.Autoplay = false; // Вимкнення Autoplay, якщо він не включений
    }

    new Carousel(slider, options);
  }
};

initializeSlider(sliders);

window.addClasses = function(slider) {
// function addClasses(slider) {
  const isDots = slider.querySelector(".carousel__dots");
  if (isDots) {
    const selectedDot = slider.querySelector(".carousel__dot.is-selected");

    slider.querySelectorAll(".carousel__dot").forEach((dot) => {
      dot.classList.remove(
          "is-next",
          "is-after-next",
          "is-prev",
          "is-before-prev",
      );
    });

    const prev = selectedDot.previousElementSibling;
    if (prev) {
      prev.classList.add("is-prev");
      if (prev.previousElementSibling) {
        prev.previousElementSibling.classList.add("is-before-prev");
      }
    }

    const next = selectedDot.nextElementSibling;
    if (next) {
      next.classList.add("is-next");
      if (next.nextElementSibling) {
        next.nextElementSibling.classList.add("is-after-next");
      }
    }
  }
};

Fancybox.bind("[data-fancybox=\"video-gallery\"]", {
  closeButton: true,
});

Fancybox.bind("[data-fancybox=\"gallery\"]", {
  Toolbar: {
    display: ["counter", "slideshow", "fullscreen", "download", "close"],
  },
});
